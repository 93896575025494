import { PageNotFound, Seo } from 'ui-components'

const Page404 = () => {
  return (
    <>
      <Seo title='Page not found' />
      <PageNotFound />
    </>
  )
}

export default Page404
